import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import {makeStyles} from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {bugs, website, server} from "variables/general.js";

import {
 dailySalesChart,
 emailsSubscriptionChart,
 completedTasksChart
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {Button} from "@material-ui/core";
import Notifications from "@material-ui/icons/Notifications";
import Hidden from "@material-ui/core/Hidden";
import Snackbar from "../../components/Snackbar/Snackbar";
import AddAlert from "@material-ui/icons/AddAlert";

const ClickToPay = function () {
 const data = JSON.stringify({"method": "goToBank"});
 const xhr = new XMLHttpRequest();
 xhr.withCredentials = true;
 xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) console.log(this.responseText);
 });
 xhr.open("GET", "service.php");
 xhr.setRequestHeader("Content-Type", "application/json");
 xhr.send(data);
};

export default function Dashboard() {
 return (
   <div>
    <GridContainer>
     <GridItem xs={12} sm={12} md={4}>
      <Button fullWidth color="primary" onClick={ClickToPay}>پرداخت</Button>
     </GridItem>

     {/*<GridItem xs={12} sm={12} md={4}>*/}
     {/* <Button fullWidth color="primary" onClick={}>*/}
     {/*  Top Left*/}
     {/* </Button>*/}
     {/*</GridItem>*/}
    </GridContainer>
   </div>
 );
}
